import './App.css';

function App() {
  return (
    <div className="app">
      <p>there is nothing to see here</p>
    </div>
  );
}

export default App;
